<template>
  <div class="container p0" id="index">
    <div class="head" :class="{ copy: showLogo }" :style="headStyle">
      <div
        id="main"
        :style="{ maxWidth: $i18n.locale == 'zh' ? '1300px' : '1600px' }"
      >
        <div class="flex">
          <img v-if="!showLogo" src="../assets/indexImg/logo1.png" />
          <div class="menu">
            <span
              class="item"
              v-for="item in menuList"
              :key="item"
              @click="scrollToView(item.id)"
            >
              {{ $t("i18n." + item.name) }}
            </span>
            <el-button type="primary" @click="isLogin(0)">
              {{ $t("i18n.signIn") }}
            </el-button>
            <el-button class="el-button--transparent" @click="isLogin(1)">
              {{ $t("i18n.signUp") }}
            </el-button>
          </div>
        </div>
        <div class="desc" v-if="showLife">
          <h1 class="top-title">精准监控 实时分享</h1>
          <p class="top-note">
            为企业提供物联网电梯管理平台解决方案<br />平台技术开发、平台运营迭代技术支持等技术服务
          </p>
        </div>
      </div>
    </div>
    <div id="monitor" class="page">
      <h3>远程实时监控电梯运行情况</h3>
      <p class="info">Core services</p>
      <div class="main">
        <div class="item" v-for="item in monitorData" :key="item">
          <img :src="item.icon" />
          <div class="con">
            <p>{{ item.title }}</p>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="rescue" class="page">
      <h3>迅速的网格救援服务</h3>
      <p class="info">Company profile</p>
      <div class="main">
        <div class="item" v-for="item in rescueData" :key="item">
          <p>{{ item.name }}</p>
          <img :src="item.icon" />
        </div>
      </div>
    </div>
    <div id="maintenance" class="page">
      <h3>日常维修保养管理</h3>
      <div class="main" :class="{ copy: showLogo }">
        <div class="top">
          <p class="subtitle">工单管理</p>
          <p class="smalltitle">管理工单相关的所有内容</p>
        </div>
        <div class="left">
          <p class="subtitle">员工管理</p>
          <p class="smalltitle">
            管理维保人员的编号、姓名、状态、工单数量、考评等级等
          </p>
        </div>
        <div class="right">
          <p class="subtitle">图表分析</p>
          <p class="smalltitle">
            以图表形式分析工单数量、类型、台量、维保人员信息等数据
          </p>
        </div>
      </div>
    </div>
    <div id="supervision" class="page">
      <h3>完整的合同数据库</h3>
      <div class="main">
        <el-carousel :autoplay="false" type="card" height="406px">
          <el-carousel-item v-for="item in supervisionData" :key="item">
            <div class="box">
              <div class="top">
                <img :src="item.icon" />
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
            <div class="bottom">{{ item.content }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div id="install" class="page">
      <h3>在线安装</h3>
      <div class="main">
        <img src="../assets/indexImg/install.png" />
        <span>
          远程监控，在线安装，处理更高效，更快捷为客户提供更专业的电梯在线安装服务
        </span>
      </div>
    </div>
    <div id="index_bottom">
      <div id="copyright">
        <div class="inline" v-for="item in codeImgs" :key="item">
          <span><span>手机扫一扫</span></span>
          <img :src="item.url" />
          <p>{{ item.name }}</p>
        </div>
        <p>{{ $t("i18n.productConsultation") }}:joycloud@joylive.com</p>
        <p>{{ $t("i18n.businessCoop") }}:13914013352&nbsp;&nbsp;13862576690</p>
        <p>{{ $t("i18n.copyright") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "Index",
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      showLife:true,
      showLogo: route.query.from === "juli",
      headStyle: {},
      menuList: [
        { name: "onlineMonitoring", id: "monitor" },
        { name: "gridRescue", id: "rescue" },
        { name: "onlineMaintenance", id: "maintenance" },
        { name: "dynamicSupervision", id: "supervision" },
        { name: "onlineInstallation", id: "install" },
        { name: "systemSetting", id: "system" },
        { name: "dynamicDetection", id: "index" },
        { name: "accessories", id: "#" },
      ],
      monitorData: [
        {
          icon: require("../../public/images/active.png"),
          title: "电梯实时运行状态",
          content:
            "所有电梯的开关门动作、上下行、所在楼层等信息通过物联网采集并实时共享到平台，以动画的形式同步展现",
        },
        {
          icon: require("../../public/images/search.png"),
          title: "任意搜索",
          content:
            "通过输入任意关键字或选择不同的选项，您可以轻松搜索任意一台产品，并查看它的实时数据",
        },
        {
          icon: require("../../public/images/select.png"),
          title: "状态筛选",
          content: "根据电梯不同的健康状况设置了筛选按钮，一键分类",
        },
        {
          icon: require("../../public/images/show.png"),
          title: "故障提示",
          content: "发生故障的电梯，根据紧急情况和故障类型，会标示不同符号",
        },
      ],
      rescueData: [
        { icon: require("../../public/images/alarm.png"), name: "困人警报" },
        { icon: require("../../public/images/message.png"), name: "即时消息" },
        {
          icon: require("../../public/images/videoCall.png"),
          name: "视频通话",
        },
        {
          icon: require("../../public/images/handleFault.png"),
          name: "故障处理",
        },
      ],
      supervisionData: [
        {
          icon: require("../../public/images/baseInfo.png"),
          title: "基础信息",
          content: "管理保养合同基础信息",
        },
        {
          icon: require("../../public/images/keyIn.png"),
          title: "录入信息",
          content: "管理续签、丢失、开票、收款等信息",
        },
        {
          icon: require("../../public/images/data.png"),
          title: "信息统计",
          content: "统计合同相关信息",
        },
      ],
      bottomData: [
        {
          label: "产品",
          color: "#49437B",
          children: [
            { name: "产品一", url: "", color: "#fff" },
            { name: "产品二", url: "", color: "#fff" },
            { name: "产品二", url: "", color: "#fff" },
            { name: "产品二", url: "", color: "#fff" },
            { name: "", url: "产品二", color: "#fff" },
          ],
        },
        {
          label: "公司网站",
          color: "#49437B",
          children: [
            {
              name: "集团官网",
              url: "http://www.joylive.com/",
              color: "#FFF08A",
            },
          ],
        },
        {
          label: "案例分析",
          color: "#49437B",
          children: [
            { name: "案例一", url: "", color: "#fff" },
            { name: "案例一", url: "", color: "#fff" },
            { name: "案例一", url: "", color: "#fff" },
            { name: "案例一", url: "", color: "#fff" },
            { name: "", url: "案例一", color: "#fff" },
          ],
        },
        {
          label: "销售服务",
          color: "#49437B",
          children: [
            { name: "网点一", url: "", color: "#fff" },
            { name: "网点一", url: "", color: "#fff" },
            { name: "网点一", url: "", color: "#fff" },
            { name: "网点一", url: "", color: "#fff" },
            { name: "", url: "网点一", color: "#fff" },
          ],
        },
      ],
      codeImgs: [
        { name: "Android", url: require("../../public/images/AndroidNew.png") },
        { name: "IOS", url: require("../../public/images/ios.png") },
      ],
    });

    onMounted(() => {
      init();
    });

    const init = async () => {
      emit("public_header", false);
      if (route.query.from) {
        localStorage.setItem("from", route.query.from);
      } else {
        localStorage.removeItem("from");
      }
      window.addEventListener("scroll", scrollTop);
    };

    const scrollTop = () => {
      let scrollTop = document.documentElement.scrollTop; //滚动条的高
      if (scrollTop > 800 && scrollTop < 4200) {
        state.showLife = false
        state.headStyle = {
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "80px",
          zIndex: "999",
          background: "linear-gradient(45deg, #3eadf4, #958af0)",
        };
      } else {
        state.headStyle = {};
        state.showLife = true
      }
    };

    const scrollToView = (id) => {
      // 指定滚动条位置
      if (id == "#") {
        router.push({ path: "/note" });
      }
      document.getElementById(id).scrollIntoView();
    };

    const isLogin = (flag) => {
      router.push({
        path: "/login",
        query: { flag: flag },
      });
    };

    return {
      ...toRefs(state),
      scrollTop,
      scrollToView,
      isLogin,
    };
  },
};
</script>
<style lang="scss">
@import "../common/scss/home.scss";
</style>
